<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex" style="align-items: center">
      <!-- LOGO -->
      <div class="navbar-brand-box d-none d-lg-block d-lg-block">
        <a href="/" class="logo logo-dark">
          <span class="logo-sm"> </span>
          <span class="logo-lg">
            <img
              alt="250x250"
              src="assets/images/logo-tanteka.png"
              data-holder-rendered="true"
              class="text-center"
              class="logo-img"
              height="40" />
          </span>
        </a>
      </div>
      <button type="button" class="btn btn-sm px-3 font-size-16 d-lg-none header-item" (click)="toggleMenubar()">
        <i class="fa fa-fw fa-bars font-size-16"></i>
      </button>
      <div style="padding-top: 25px">
        <ul style="list-style-type: none; border-left: 1px solid #ddd; padding-left: 20px">
          <li *ngFor="let breadcrumb of breadcrumbs">
            <h5 style="padding: 10px">{{ breadcrumb.label }}</h5>
          </li>
        </ul>
      </div>
    </div>
    <div class="d-flex">
      <div class="d-inline-block d-none d-lg-block d-lg-block" style="padding-top: 17px"></div>

      <div class="top-nav dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item noti-icon right-bar-toggle"
          id="page-header-notifications-dropdown"
          ngbDropdownToggle>
          <i class="fa fa-bell"></i>
          <span *ngIf="total > 0" class="badge badge-pill badge-danger" style="top: 16px; margin-left: -7px">{{
            total
          }}</span>
        </button>
        <div
          class="dropdown-menu dropdown-menu-sm dropdown-menu-right p-0"
          ngbDropdownMenu
          aria-labelledby="page-header-notifications-dropdown">
          <div ngbDropdown style="width: 300px; height: 500px; overflow: scroll; overflow-x: hidden">
            <div class="dropdown">
              <ng-container *ngFor="let vals of listNotifikasi; index as i">
                <div class="dropdown-item" style="border-bottom: 1px solid #c2cfd6; display: flex; flex-wrap: wrap">
                  <strong style="white-space: break-spaces">{{ vals.judul }}</strong
                  ><br />
                  <div style="white-space: break-spaces">
                    <span style="font-size: 12px; font-weight: 400">{{ vals.keterangan }}</span>
                  </div>
                  <span style="margin-top: 2em; margin-left: auto; font-size: 12px; font-weight: 400">{{
                    vals.tanggal
                  }}</span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div
        class="top-nav dropdown d-inline-block"
        ngbDropdown
        *ngIf="checkAkses('list_user') || checkAkses('list_roles')">
        <button
          type="button"
          class="btn header-item noti-icon right-bar-toggle"
          id="page-header-notifications-dropdown"
          ngbDropdownToggle>
          <i class="fa fa-cog"></i>
        </button>
        <div
          class="dropdown-menu dropdown-menu-sm dropdown-menu-right p-0"
          ngbDropdownMenu
          aria-labelledby="page-header-notifications-dropdown">
          <div class="" ngbDropdown>
            <div class="dropdown">
              <div
                *ngIf="checkAkses('list_user')"
                class="dropdown-item"
                [routerLink]="['/master/master-user']"
                style="cursor: pointer">
                <a style="color: #495057" href="#" [routerLink]="['/master/master-user']" class="text-black"> User </a>
              </div>
              <div
                *ngIf="checkAkses('list_roles')"
                class="dropdown-item"
                [routerLink]="['/master/master-akses']"
                style="cursor: pointer">
                <a style="color: #495057" href="#" [routerLink]="['/master/master-akses']" class="text-black">
                  Roles
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="dropdown d-inline-block" ngbDropdown>
        <button type="button" class="btn header-item" id="page-header-user-dropdown" ngbDropdownToggle>
          <ng-template #tipUser>
            <div style="min-width: 100px; max-width: 200px; text-align: left">
              <b>{{ user.nama }}</b
              ><br />
              {{ user.jabatanTxt }}
            </div>
          </ng-template>
          <img
            class="rounded-circle header-profile-user"
            src="assets/images/users/user-default.png"
            placement="bottom"
            [ngbTooltip]="tipUser"
            tooltipClass="tooltipUser" />
          <span class="d-none d-xl-inline-block ml-1"
            >{{ user.nama }} &nbsp;
            <i class="fas fa-angle-down"></i>
          </span>
          <i class="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
        </button>
        <div class="dropdown-menu dropdown-menu-right" ngbDropdownMenu>
          <div class="dropdown-divider d-lg-none"></div>
          <a class="dropdown-item d-block" href="javascript: void(0);" (click)="gantiPassword(modalGPassword)">
            <i class="fas fa-key font-size-12 align-middle mr-1"></i>
            Change Password
          </a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item text-danger" href="javascript: void(0);" (click)="logout()">
            <i class="fas fa-power-off font-size-12 align-middle mr-1 text-danger"></i>
            Logout
          </a>
        </div>
      </div>
    </div>
  </div>
</header>
<div class="topnav">
  <div class="container-fluid">
    <nav class="navbar navbar-light navbar-expand-lg topnav-menu active">
      <div class="collapse navbar-collapse active" id="topnav-menu-content">
        <ul class="navbar-nav">
          <li class="nav-item">
            <a
              class="nav-link arrow-none"
              (click)="onMenuClick($event)"
              [routerLink]="['/home']"
              id="topnav-components"
              role="button">
              <i class="fa fa-home mr-2"></i>
              Dashboards
            </a>
          </li>

          <div
            ngbDropdown
            *ngIf="
              checkAkses('list_item') ||
              checkAkses('list_satuan') ||
              checkAkses('list_katalog') ||
              checkAkses('list_cara_pembayaran') ||
              checkAkses('list_supplier')
            ">
            <li id="steps-master" class="nav-item dropdown" ngbDropdownToggle>
              <a
                class="nav-link dropdown-toggle arrow-none side-nav-link-ref"
                href="#"
                id="topnav-master"
                role="button"
                (click)="onMenuClick($event)">
                <i class="fas fa-list mr-2"></i> Master Data
                <div class="arrow-down"></div>
              </a>
              <div class="dropdown-menu" ngbDropdownMenu>
                <div *ngIf="checkAkses('list_item')" class="dropdown">
                  <a
                    class="dropdown-item dropdown-toggle arrow-none side-nav-link-ref"
                    [routerLink]="['/master/item']"
                    href="#"
                    id="topnav-mitem"
                    role="button"
                    (click)="onMenuClick($event); reloadCurrentRoute('/master/item')">
                    Item
                  </a>
                </div>

                <div *ngIf="checkAkses('list_satuan')" class="dropdown">
                  <a
                    class="dropdown-item dropdown-toggle arrow-none side-nav-link-ref"
                    [routerLink]="['/master/satuan']"
                    href="#"
                    id="topnav-mitem"
                    role="button"
                    (click)="onMenuClick($event); reloadCurrentRoute('/master/satuan')">
                    Satuan
                  </a>
                </div>

                <div *ngIf="checkAkses('list_katalog')" class="dropdown">
                  <a
                    class="dropdown-item dropdown-toggle arrow-none side-nav-link-ref"
                    [routerLink]="['/master/katalog']"
                    href="#"
                    id="topnav-mitem"
                    role="button"
                    (click)="onMenuClick($event); reloadCurrentRoute('/master/katalog')">
                    Katalog
                  </a>
                </div>

                <div *ngIf="checkAkses('list_cara_pembayaran')" class="dropdown">
                  <a
                    class="dropdown-item dropdown-toggle arrow-none side-nav-link-ref"
                    [routerLink]="['/master/cara-pembayaran']"
                    href="#"
                    id="topnav-mitem"
                    role="button"
                    (click)="onMenuClick($event); reloadCurrentRoute('/master/cara-pembayaran')">
                    Cara Pembayaran
                  </a>
                </div>

                <div *ngIf="checkAkses('list_supplier')" class="dropdown">
                  <a
                    class="dropdown-item dropdown-toggle arrow-none side-nav-link-ref"
                    [routerLink]="['/master/supplier']"
                    href="#"
                    id="topnav-mitem"
                    role="button"
                    (click)="onMenuClick($event); reloadCurrentRoute('/master/supplier')">
                    Supplier
                  </a>
                </div>
              </div>
            </li>
          </div>

          <div
            *ngIf="checkAkses('list_pesanan') || checkAkses('list_kasir') || checkAkses('list_pengambilan_kas')"
            ngbDropdown>
            <li id="steps-transaksi" class="nav-item dropdown" ngbDropdownToggle>
              <a
                class="nav-link dropdown-toggle arrow-none side-nav-link-ref"
                href="#"
                id="topnav-transaksi"
                role="button"
                (click)="onMenuClick($event)">
                <i class="far fa-money-bill-alt mr-2"></i> Transaksi
                <div class="arrow-down"></div>
              </a>
              <div class="dropdown-menu" ngbDropdownMenu>
                <div *ngIf="checkAkses('list_pesanan')" class="dropdown">
                  <a
                    class="dropdown-item dropdown-toggle arrow-none side-nav-link-ref"
                    [routerLink]="['/transaksi/pesanan']"
                    href="#"
                    id="topnav-tpesanan"
                    role="button"
                    (click)="onMenuClick($event); reloadCurrentRoute('/transaksi/pesanan')">
                    List Pesanan
                  </a>
                </div>
                <div *ngIf="checkAkses('list_kasir')" class="dropdown">
                  <a
                    class="dropdown-item dropdown-toggle arrow-none side-nav-link-ref"
                    [routerLink]="['/transaksi/kasir']"
                    href="#"
                    id="topnav-tpenjualan"
                    role="button"
                    (click)="onMenuClick($event); reloadCurrentRoute('/transaksi/kasir')">
                    Kasir
                  </a>
                </div>
                <div *ngIf="checkAkses('list_pengambilan_kas')" class="dropdown">
                  <a
                    class="dropdown-item dropdown-toggle arrow-none side-nav-link-ref"
                    [routerLink]="['/transaksi/pengambilan-kas']"
                    href="#"
                    id="topnav-tpengambilankas"
                    role="button"
                    (click)="onMenuClick($event); reloadCurrentRoute('/transaksi/pengambilan-kas')">
                    Modal Perputaran
                  </a>
                </div>
              </div>
            </li>
          </div>

          <div
            *ngIf="checkAkses('list_penerimaan') || checkAkses('list_pengeluaran') || checkAkses('list_transfer_item')"
            ngbDropdown>
            <li id="steps-inventory" class="nav-item dropdown" ngbDropdownToggle>
              <a
                class="nav-link dropdown-toggle arrow-none side-nav-link-ref"
                href="#"
                id="topnav-inventory"
                role="button"
                (click)="onMenuClick($event)">
                <i class="fas fa-tasks mr-2"></i> Inventory
                <div class="arrow-down"></div>
              </a>
              <div class="dropdown-menu" ngbDropdownMenu>
                <!--                                <div class="dropdown" *ngIf="checkAkses('list_penerimaan')">-->
                <div *ngIf="checkAkses('list_penerimaan')" class="dropdown">
                  <a
                    class="dropdown-item dropdown-toggle arrow-none side-nav-link-ref"
                    [routerLink]="['/transaksi/penerimaan']"
                    href="#"
                    id="topnav-tpenerimaan"
                    role="button"
                    (click)="onMenuClick($event); reloadCurrentRoute('/transaksi/penerimaan')">
                    Penerimaan (Kulakan)
                  </a>
                </div>
                <!-- <div class="dropdown" *ngIf="checkAkses('list_pengeluaran')"> -->
                <div *ngIf="checkAkses('list_pengeluaran')" class="dropdown">
                  <a
                    class="dropdown-item dropdown-toggle arrow-none side-nav-link-ref"
                    [routerLink]="['/transaksi/pengeluaran']"
                    href="#"
                    id="topnav-tpengeluaran"
                    role="button"
                    (click)="onMenuClick($event); reloadCurrentRoute('/transaksi/pengeluaran')">
                    Pengeluaran
                  </a>
                </div>

                <div *ngIf="checkAkses('list_transfer_item')" class="dropdown">
                  <a
                    class="dropdown-item dropdown-toggle arrow-none side-nav-link-ref"
                    [routerLink]="['/transaksi/transfer-item']"
                    href="#"
                    id="topnav-transfer-item"
                    role="button"
                    (click)="onMenuClick($event); reloadCurrentRoute('/transaksi/transfer-item')">
                    Transfer Item
                  </a>
                </div>
                <div *ngIf="checkAkses('list_stok_minimum')" class="dropdown">
                  <a
                    class="dropdown-item dropdown-toggle arrow-none side-nav-link-ref"
                    [routerLink]="['/master/stok-minimum']"
                    href="#"
                    id="topnav-stokminimum"
                    role="button"
                    (click)="onMenuClick($event); reloadCurrentRoute('/master/stok-minimum')">
                    Stok Minimum
                  </a>
                </div>
              </div>
            </li>
          </div>

          <div *ngIf="checkAkses('list_stok_mini_gudang') || checkAkses('list_pembelian_bidang') || checkAkses('list_penjualan') || checkAkses('list_penjualan_bidang') || checkAkses('list_penerimaan_hutang') || checkAkses('list_pengambilan_kas') || checkAkses('list_belanja_bidang')" ngbDropdown>
            <li id="steps-laporan" class="nav-item dropdown" ngbDropdownToggle>
              <a
                class="nav-link dropdown-toggle arrow-none side-nav-link-ref"
                href="#"
                id="topnav-laporan"
                role="button"
                (click)="onMenuClick($event)">
                <i class="fas fa-calendar mr-2"></i> Laporan
                <div class="arrow-down"></div>
              </a>
              <div  class="dropdown-menu" ngbDropdownMenu>
                <div *ngIf="checkAkses('list_stok_mini_gudang')" class="dropdown">
                  <a
                    class="dropdown-item dropdown-toggle arrow-none side-nav-link-ref"
                    [routerLink]="['/laporan/laporan-kartu-stok']"
                    href="#"
                    id="topnav-lkartustok"
                    role="button"
                    (click)="onMenuClick($event); reloadCurrentRoute('/laporan/laporan-kartu-stok')">
                    Stok Mini Gudang
                  </a>
                </div>
                <div *ngIf="checkAkses('list_pembelian_bidang')" class="dropdown">
                  <a
                    class="dropdown-item dropdown-toggle arrow-none side-nav-link-ref"
                    [routerLink]="['/laporan/laporan-pembelian-bidang']"
                    href="#"
                    id="topnav-lpembelianbidang"
                    role="button"
                    (click)="onMenuClick($event); reloadCurrentRoute('/laporan/laporan-pembelian-bidang')">
                    Pembelian Bidang
                  </a>
                </div>
                <div *ngIf="checkAkses('list_penjualan')" class="dropdown">
                  <a
                    class="dropdown-item dropdown-toggle arrow-none side-nav-link-ref"
                    [routerLink]="['/laporan/laporan-penjualan']"
                    href="#"
                    id="topnav-lpenjualan"
                    role="button"
                    (click)="onMenuClick($event); reloadCurrentRoute('/laporan/laporan-penjualan')">
                    Penjualan
                  </a>
                </div>

                <div *ngIf="checkAkses('list_penjualan_bidang')" class="dropdown">
                  <a
                    class="dropdown-item dropdown-toggle arrow-none side-nav-link-ref"
                    [routerLink]="['/laporan/laporan-penjualan-bidang']"
                    href="#"
                    id="topnav-lpenjualanbidang"
                    role="button"
                    (click)="onMenuClick($event); reloadCurrentRoute('/laporan/laporan-penjualan-bidang')">
                    Penjualan Bidang
                  </a>
                </div>

                <div *ngIf="checkAkses('list_penerimaan_hutang')" class="dropdown">
                  <a
                    class="dropdown-item dropdown-toggle arrow-none side-nav-link-ref"
                    [routerLink]="['/laporan/laporan-penerimaan-hutang']"
                    href="#"
                    id="topnav-lpenerimaanhutang"
                    role="button"
                    (click)="onMenuClick($event); reloadCurrentRoute('/laporan/laporan-penerimaan-hutang')">
                    Penerimaan Hutang
                  </a>
                </div>

                <div *ngIf="checkAkses('list_pengambilan_kas')" class="dropdown">
                  <a
                    class="dropdown-item dropdown-toggle arrow-none side-nav-link-ref"
                    [routerLink]="['/laporan/laporan-pengambilan-kas']"
                    href="#"
                    id="topnav-lpengambilankas"
                    role="button"
                    (click)="onMenuClick($event); reloadCurrentRoute('/laporan/laporan-pengambilan-kas')">
                    Modal Perputaran
                  </a>
                </div>

                <div *ngIf="checkAkses('list_belanja_bidang')" class="dropdown">
                  <a
                    class="dropdown-item dropdown-toggle arrow-none side-nav-link-ref"
                    [routerLink]="['/laporan/laporan-belanja-bidang']"
                    href="#"
                    id="topnav-lbelanjabidang"
                    role="button"
                    (click)="onMenuClick($event); reloadCurrentRoute('/laporan/laporan-belanja-bidang')">
                    Belanja Bidang
                  </a>
                </div>
              </div>
            </li>
          </div>
        </ul>
      </div>
    </nav>
  </div>
</div>

<ng-template #modalGPassword let-modal>
  <div class="modal-header">
    <h5 class="modal-title mt-0">Change Password</h5>
    <button type="button" class="close" (click)="modal.dismiss('Cross click')" aria-label="Close">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div class="form-group row">
          <label class="col-md-4 col-form-label col-form-label-sm required">Password</label>
          <div class="col-md-8">
            <input
              class="form-control form-control-sm"
              type="password"
              [(ngModel)]="model.password"
              name="password"
              placeholder="Input New Password" />
          </div>
        </div>
        <div class="form-group row">
          <label class="col-md-4 col-form-label col-form-label-sm required">Confirm Password</label>
          <div class="col-md-8">
            <input
              class="form-control form-control-sm"
              type="password"
              [(ngModel)]="model.repassword"
              name="repassword"
              placeholder="Confirm New Password" />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <div class="float-right">
      <button
        class="btn btn-primary btn-sm"
        (click)="savepasswords(); modal.dismiss('Cross click')"
        [disabled]="model.password != model.repassword">
        <i class="fa fa-save font-size-14 align-middle mr-2"></i>
        Save
      </button>
    </div>
  </div>
</ng-template>
